
import { Vue, Component } from "vue-property-decorator";
import { mapGetters, mapActions } from "vuex";
import TableHeader from "@/components/tableHeader.vue";
import IbotechService from '@/services/ibotechService';
import UserAccess from "@/model/User/UserAccess";
import Multiselect from "vue-multiselect";
import * as XLSX from 'xlsx';

import { Device } from "@/interface/DeviceInterface";

@Component({
  components: { TableHeader, Multiselect },
  computed: {
    ...mapGetters(["getAllLocationsData", "getUserAccess", "isIbotech"]),
  },
  methods: {
    ...mapActions(["getAllLocations"])
  }
})
export default class Location extends Vue {
  readonly getUserAccess!: UserAccess;
  private getAllLocationsData!: Array<object>;

  private getAllLocations!: Function;

  perPage: number = 30;
  currentPage: number = 1;
  loading = false;
  reportProjectId = "";
  filter: string = "";
  searchInTableFor: string = "";
  editLocation: boolean = false;
  locationTypes: Array<{ label: string, value: string }> = [
    { label: "BAUSTELLE", value: "BAUSTELLE" },
    { label: "LAGER", value: "LAGER" }
  ];
  form = {
    id: "",
    description: "",
    locationType: {
      label: "",
      value: ""
    },
    projectId: "",
    notice: ""
  }
  monthlyReportForm = {
    from: "",
    to: ""
  }
  fields: Array<string | any> = [
    {
      key: "selected",
      sortable: false,
      label: this.translateTableLabel('selected'),
    },
    {
      key: "id",
      sortable: false,
      label: "ID"
    },
    {
      key: "projectId",
      sortable: false,
      label: this.translateTableLabel('projectId')
    },
    {
      key: "description",
      sortable: false,
      label: this.translateTableLabel('description')
    },
    {
      key: "locationType",
      sortable: false,
      label: this.translateTableLabel('locationType')
    },
    {
      key: "notice",
      sortable: false,
      label: this.translateTableLabel('notice')
    },
    {
      key: "actions",
      sortable: false,
      label: this.translateTableLabel('actions')
    }
  ];

  created() {

    // Redirect to 404
    if(!this.getUserAccess.AccessIbotech.VIEW.location) {
      this.$router.push({ name: 'NotFound' });
    }

    this.loading = true;

    // Get - Set Vehicle Data
    this.getAllLocations().then(() => {
      this.loading = false;
    });

  }

  openReportModal(projectId: any) {

    this.reportProjectId = projectId;
    this.$bvModal.show('monthlyReport')

    this.resetMonthlyReportForm();

  }

  openModalNewLocation(actions: any, data: any) {

    this.resetForm();
    this.editLocation = false;

    if(actions == 'edit') {
      this.editLocation = true;

      this.form.description = data.item.description;
      this.form.projectId = data.item.projectId;
      this.form.notice = data.item.notice;

      // ToDo: Überprüfen ????
      this.form.id = data.item.id;
      const locationType = this.locationTypes.find(type => type.value === data.item.locationType);

      if(locationType) {
        this.form.locationType = locationType
      } else {
        console.warn("Location type not found: ", locationType);
      }
    }

    this.$bvModal.show('newLocation');
  }

  resetMonthlyReportValues() {
    this.$bvModal.hide("monthlyReport");
    this.resetMonthlyReportForm();

  }

  resetMonthlyReportForm() {
    this.monthlyReportForm = {
      from: "",
      to: ""
    }
  }

  //
  resetLocationValues() {

    this.$bvModal.hide("newLocation");
    this.resetForm();

  }

  resetForm() {
    this.form = {
      id: "",
      description: "",
      locationType: {
        label: "",
        value: ""
      },
      projectId: "",
      notice: "",
    }
  }

  async deleteLocation(projectId: any) {

    // TODO: Check if Location is already used in records

    const res = await IbotechService.deleteLocation(projectId);

    if(res.status == 200) {

      if(res.data) {

        // Success Message
        this.$bvToast.toast((this.$t('ibotech_vehicle.deleteSuccess')).toString(), {
          title: (this.$t('ibotech_vehicle.newVehicleForm.successMessageTitle')).toString(),
          autoHideDelay: 1500,
          variant: 'success'
        });

        this.getAllLocations();

      } else {

        // Info Message
        this.$bvToast.toast((this.$t('ibotech_location.deleteNotAvailableText')).toString(), {
          title: (this.$t('ibotech_location.deleteNotAvailableTitle')).toString(),
          autoHideDelay: 1500,
          variant: 'info'
        });
      }

    } else {
      this.error();
    }
  }

  confirmDeleteLocation(projectId: any) {

    this.$bvModal
      .msgBoxConfirm((this as any).$t("ibotech_vehicle.deleteVehicle"), {
        title: (this as any).$t("inventory.deviceActions.archiveDevice.modalTitle"),
        size: "md",
        buttonSize: "md",
        okVariant: "success",
        headerClass: "p-2 border-bottom-0 bg-light",
        footerClass: "p-2 border-top-0",
        centered: true,
        okTitle: (this as any).$t("utils.alertBox.ok"),
        cancelVariant: "light",
        cancelTitle: (this as any).$t("utils.alertBox.cancel")
      })
      .then((value: boolean) => {
        if (value) {
          this.deleteLocation(projectId);

        } else {
          return false;
        }
      });
  }

  async submitNewLocation() {

    if(this.isFormValid) {

      let res;
      // this.form.locationType = this.form.locationType.label;

      if(this.editLocation) {
        res = await IbotechService.putOrPostLocation(this.form, "POST");

      } else {
        res = await IbotechService.putOrPostLocation(this.form, "PUT");
      }


      if(res.status == 202) {
        this.successCreate();
        this.getAllLocations();

      } else {
        this.error();
      }
    }
  }

  async createMonthlyReport() {

    if(this.monthlyReportForm) {

      const from = new Date(`${this.monthlyReportForm.from}T00:00:00`).toISOString();
      const to = new Date(`${this.monthlyReportForm.to}T23:59:59`).toISOString();

      var data = {
        projectId: this.reportProjectId,
        from: from,
        to: to
      }

      const res = await IbotechService.getMontlyReport(data);

      if(res.status == 200) {

        // Success
        this.$bvModal.hide('monthlyReport');
        this.success();

        if(res.data.length > 0) {

          res.data.forEach((item: any) => {
            delete item.location;
          });

          // Definieren der gewünschten Reihenfolge der Schlüssel
          const desiredOrder: string[] = ["projectId", "baustelle", "start", "end", "vehicleType", "vehicleId", "dailyCost", "attendedDays", "calculatedCost"];

          // Sortieren der Daten gemäß der gewünschten Reihenfolge
          const sortedData = res.data.map((item: { [key: string]: any }) => {
            const sortedItem: { [key: string]: any } = {};
            desiredOrder.forEach((key: string) => {
              sortedItem[key] = item[key];
            });
            return sortedItem;
          });

          this.convertToXLSX(sortedData);

        } else {
          this.infoNoData();
        }

      } else {
        this.error();
      }
    }

  }

  convertToXLSX(data: any) {
    // Holen der Schlüssel aus dem ersten Datenobjekt und Übersetzen der Header
    const keys = Object.keys(data[0]);
    const translatedKeys = keys.map(key => this.$t(`ibotech_location.excelReport.${key}`).toString());

    // Formatieren der Datenzeilen
    const formattedData = this.formattedData(keys, data);

    // Erstellen des Arbeitsblatts
    const ws = XLSX.utils.json_to_sheet(formattedData, { header: translatedKeys });

    // Anpassen der Spaltenbreiten
    const columnWidths = this.calculateColumnWidth(translatedKeys, formattedData);
    ws['!cols'] = columnWidths;

    // Erstellen der Arbeitsmappe
    const wb = XLSX.utils.book_new();

    // Download der Excel-Datei
    this.downloadExcel(wb, ws);
  }

  downloadExcel(workbook: XLSX.WorkBook, worksheet: XLSX.WorkSheet) {
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'export.xlsx');
  }

  calculateColumnWidth(headers: string[], data: { [key: string]: any }[]): { wch: number }[] {
    return headers.map((header) => {
      const columnData = data.map(row => row[header] ? row[header].toString().length : 0);
      const headerLength = header.length;
      const maxLength = Math.max(headerLength, ...columnData);
      return { wch: maxLength + 2 };
    });
  }

  formattedData(keys: string[], data: any[]): { [key: string]: any }[] {
    return data.map((row: any) => {
      const formattedRow = { ...row };
      formattedRow.start = this.formatDateIntoGermanDate(row.start);
      formattedRow.end = this.formatDateIntoGermanDate(row.end);
      return keys.reduce((acc: { [key: string]: any }, key: string) => {
        acc[this.translateExportLabel(key)] = formattedRow[key];
        return acc;
      }, {});
    });
  }

  formatDateIntoGermanDate(dateString: string): string {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString('de-DE', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
    const formattedTime = date.toLocaleTimeString('de-DE', {
      hour: '2-digit',
      minute: '2-digit',
    });
    return `${formattedDate} ${formattedTime}`;
  }

  translateFulldayBooking(booking: boolean): string {
    return booking ? 'Ja' : 'Nein';
  }

  translateExportLabel(label: any) {
    return this.$t(`ibotech_location.excelReport.${label}`).toString();
  }


  successCreate() {

    // Modal Reset
    this.resetLocationValues()

    this.success();

  }

  success() {
    // Success Message
    this.$bvToast.toast((this.$t('ibotech_vehicle.newVehicleForm.successMessageText')).toString(), {
      title: (this.$t('ibotech_vehicle.newVehicleForm.successMessageTitle')).toString(),
      autoHideDelay: 1500,
      variant: 'success'
    })
  }

  error() {
    this.$bvToast.toast((this.$t('ibotech_vehicle.newVehicleForm.errorMessageText')).toString(), {
      title: (this.$t('ibotech_vehicle.newVehicleForm.errorMessageTitle')).toString(),
      autoHideDelay: 1500,
      variant: 'danger'
    })
  }

  infoNoData() {
    this.$bvToast.toast((this.$t('ibotech_vehicle.infoNoReportDataText')).toString(), {
      title: (this.$t('ibotech_vehicle.infoNoReportDataTitle')).toString(),
      autoHideDelay: 1500,
      variant: 'info'
    })
  }

  translateTableLabel(label: string) {
    return this.$t(`ibotech_location.locationTableHeadlines.${label}`);
  }

  onRowSelected(location: Array<any>) {
    this.$emit("rowSelected", location);
  }

  filterActionHandler(filterAction: string) {
    this.filter = filterAction;
  }

  get getData() {
    return this.getAllLocationsData;
  }

  get isFormValid() {
    return Object.entries(this.form).every(([key, value]) => key === 'notice' || key === 'id' || (value !== '' && value !== null) );
  }

  get isMonthlyReportFormValid() {
    return Object.values(this.monthlyReportForm).every(value => value !== '' && value !== null);
  }
}
